import React, { useEffect, useState } from "react";
import "./ChatContent.css";
import { formatDate } from "../../../Common";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DisplayContacts from "./DisplayContacts";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { parseFormattedText } from "../../../Common";
import CallIcon from "@mui/icons-material/Call";
import LaunchIcon from "@mui/icons-material/Launch";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import ForwardMessage from "./ForwardMessage";
import ReplyMsgCard from "./ReplyMsgCard";

const sentStatus = {
  color: "#383194",
  width: "16px",
  position: "relative",
  left: "10",
  height: "15px",
};
const readStatus = {
  color: "#da1e7a",
  width: "16px",
  position: "relative",
  left: "10",
  height: "15px",
};
const failedStatus = {
  color: "red",
  width: "16px",
  position: "relative",
  left: "10",
  height: "15px",
};
function Message(props) {
  const { bubble, data, scrollToMessage } = props;
  const [fileName, setFileName] = useState("");
  const [showForwardModal, setShowForwardModal] = useState(false);
  const [showForwardIcon, setShowForwardIcon] = useState(false);
  useEffect(() => {
    if (data?.header?.header_content_url) {
      var link = data?.header?.header_content_url.split("?")[0];
      var file = link.split("/").pop();
      setFileName(file);
    }
  }, [data]);

  const { parent_message_id } = data;
  const handleForwardClick = () => {
    setShowForwardModal(true);
  };

  const status = (data) => {
    if (data == "sent") {
      return <DoneIcon style={sentStatus} />;
    }
    if (data == "delivered") {
      return <DoneAllIcon style={sentStatus} />;
    }
    if (data == "read") {
      return <DoneAllIcon style={readStatus} />;
    }
    if (data == "failed" || data == undefined) {
      return <ErrorOutlineIcon style={failedStatus} />;
    }
  };

  const getFileIcon = (fileType) => {
    const audioTypes = [
      "audio/aac",
      "audio/mp4",
      "audio/mpeg",
      "audio/amr",
      "audio/ogg",
    ];
    const pdfType = "application/pdf";
    const imageTypes = ["image/jpeg", "image/png", "image/webp"];
    const documentTypes = [
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const spreadsheetTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const presentationTypes = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    const videoTypes = ["video/3gp", "video/mp4"];

    if (audioTypes.includes(fileType)) {
      return <AudiotrackIcon />;
    } else if (fileType === pdfType) {
      return <PictureAsPdfIcon />;
    } else if (imageTypes.includes(fileType)) {
      return <ImageIcon />;
    } else if (documentTypes.includes(fileType)) {
      return <DescriptionIcon />;
    } else if (spreadsheetTypes.includes(fileType)) {
      return <TableChartIcon />;
    } else if (presentationTypes.includes(fileType)) {
      return <SlideshowIcon />;
    } else if (videoTypes.includes(fileType)) {
      return <MovieIcon />;
    } else {
      return <DescriptionIcon />;
    }
  };
  const getFileName = (originalString) => {
    if (originalString.length > 25) {
      const first16Chars = originalString.slice(0, 16);

      const last5Chars = originalString.slice(-6);

      const combinedString = `${first16Chars}...${last5Chars}`;

      return combinedString;
    } else return originalString;
  };
  const ifCallTOAction = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type === "URL" || obj.type === "PHONE_NUMBER")
    ) {
      return (
        <div style={{ borderTop: "1px solid #dadde1" }}>
          {buttons?.map((item) => (
            <p
              style={{
                color: "#ffff",
                textAlign: "center",
                margin: "5px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                fontWeight: "normal",
              }}
            >
              {item.type == "PHONE_NUMBER" ? (
                <CallIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              ) : (
                <LaunchIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              )}
              <a
                href={item.url}
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
              >
                {item.text}
              </a>
            </p>
          ))}
        </div>
      );
    }
  };
  const ifQuickreply = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (buttons?.some((obj) => obj.type === "QUICK_REPLY")) {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "295px",
            justifyContent: "center",
          }}
        >
          {buttons?.map((item) => (
            <p
              style={{
                color: "#d51f7b",
                textAlign: "center",
                margin: "2px",
                background: "#f2f2f2",
                minWidth: "100px",
                cursor: "pointer",
                padding: "8px",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "240px",
                flexGrow: "2",
              }}
            >
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };

  const automaticReply = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;

    return (
      <div
      // style={{
      //   flexWrap: "wrap",
      //   maxWidth: "295px",
      //   justifyContent: "center",
      // }}
      >
        {buttons?.map((item) => (
          <p
            style={{
              color: "#d51f7b",
              textAlign: "center",
              margin: "2px",
              marginTop: "5px",
              background: "#f2f2f2",
              minWidth: "100px",
              cursor: "pointer",
              padding: "8px",
              borderRadius: "8px",
              width: "100%",
              maxWidth: "240px",
              flexGrow: "2",
            }}
          >
            {item}
          </p>
        ))}
      </div>
    );
  };
  return (
    <>
      <div className={bubble} id={`${data.message_id}`}>
        {bubble === "received" ? (
          <div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
              className="profilePic"
            />
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onMouseEnter={() => setShowForwardIcon(true)}
          onMouseLeave={() => setShowForwardIcon(false)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {showForwardIcon &&
              !data.is_private_note &&
              bubble === "sender" && (
                <FontAwesomeIcon
                  icon={faShare}
                  style={{
                    cursor: "pointer",
                    marginRight: "5px",
                    marginLeft: "5px",
                    color: "#d51f7b",
                  }}
                  onClick={handleForwardClick}
                />
              )}
          </div>
          <div>
            {data.is_private_note ? (
              data.message_type == "attach_contact" ? (
                <>
                  <div className={`noteMessage`}>
                    <p className="noteTitle">Private Note:</p>
                    <DisplayContacts data={data} />
                  </div>
                </>
              ) : data.message_type == "attach_file" ? (
                <>
                  <div className={`messageAttachFile`}>
                    {["image/jpeg", "image/png", "image/webp"].includes(
                      data.file_type
                    ) && data.file_link ? (
                      <a
                        href={data.file_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={data.file_link}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            border: "1px",
                          }}
                        />
                      </a>
                    ) : (
                      <div>
                        {getFileIcon(data.file_type)}
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                            paddingTop: "5px",
                            borderTop: "1px solid #f2f2f2",
                          }}
                          href={data.file_link}
                          target="_blank"
                        >
                          {getFileName(data.file_name)}
                        </a>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={`noteMessage`}>
                    <p className="noteTitle">Private Note:</p>
                    <p>{parseFormattedText(data?.content)}</p>
                  </div>
                </>
              )
            ) : data.message_type == "template" ? (
              <>
                <div
                  className={`message`}
                  style={{
                    width: data?.content.length > 300 ? "350px" : "225px",
                  }}
                >
                  {parent_message_id && (
                    <ReplyMsgCard
                      data={parent_message_id}
                      getFileIcon={getFileIcon}
                      getFileName={getFileName}
                      parseFormattedText={parseFormattedText}
                      scrollToMessage={scrollToMessage}
                    />
                  )}

                  {data?.header?.header_type == "image" && (
                    <a
                      href={data.header.header_content_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data?.header?.header_content_url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          border: "1px",
                        }}
                      />
                    </a>
                  )}
                  {data?.header?.header_type == "document" && (
                    <>
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                        }}
                        href={data?.header?.header_content_url}
                        target="_blank"
                        name="header"
                      >
                        <PictureAsPdfIcon
                          style={{ marginRight: "5px", color: "white" }}
                        />
                        <span style={{ color: "white" }}>
                          {getFileName(fileName)}
                        </span>
                      </a>
                      <div
                        style={{
                          width: "100%",
                          borderTop: "1px solid white",
                          margin: "5px 0",
                        }}
                      />
                    </>
                  )}
                  <p>
                    {parseFormattedText(data?.content)}
                    {data?.buttons && ifCallTOAction(data?.buttons)}
                  </p>
                </div>
                {data?.buttons && ifQuickreply(data?.buttons)}
              </>
            ) : data.message_type == "attach_contact" ? (
              <>
                <div
                  className={`message`}
                  style={{
                    width: data?.content.length > 300 ? "350px" : "225px",
                  }}
                >
                  {parent_message_id && (
                    <ReplyMsgCard
                      data={parent_message_id}
                      getFileIcon={getFileIcon}
                      getFileName={getFileName}
                      parseFormattedText={parseFormattedText}
                      scrollToMessage={scrollToMessage}
                    />
                  )}

                  <DisplayContacts data={data} />
                </div>
              </>
            ) : data.message_type == "attach_file" ? (
              <>
                <div className={`messageAttachFile`}>
                  {["image/jpeg", "image/png", "image/webp"].includes(
                    data.file_type
                  ) && data.file_link ? (
                    <a
                      href={data.file_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data.file_link}
                        style={{
                          width: "200px",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          border: "1px solid #da1e7a",
                          marginRight: "5px",
                        }}
                      />
                    </a>
                  ) : (
                    <div
                      className={`message`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {getFileIcon(data.file_type)}
                      <a
                        href={data.file_link}
                        target="_blank"
                        style={{ color: "#f3eeef" }}
                      >
                        {getFileName(data.file_name)}
                      </a>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`message`}
                  style={{
                    width: data?.content?.length > 300 ? "350px" : "225px",
                  }}
                >
                  {parent_message_id && (
                    <ReplyMsgCard
                      data={parent_message_id}
                      getFileIcon={getFileIcon}
                      getFileName={getFileName}
                      parseFormattedText={parseFormattedText}
                      scrollToMessage={scrollToMessage}
                    />
                  )}

                  {data?.header?.header_type == "image" && (
                    <a
                      href={data.header.header_content_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data?.header?.header_content_url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          border: "1px",
                        }}
                      />
                    </a>
                  )}
                  {data?.header?.header_type == "document" && (
                    <>
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                        }}
                        href={data?.header?.header_content_url}
                        target="_blank"
                        name="header"
                      >
                        <PictureAsPdfIcon
                          style={{ marginRight: "5px", color: "white" }}
                        />
                        <span style={{ color: "white" }}>{fileName}</span>
                      </a>
                      <div
                        style={{
                          width: "100%",
                          borderTop: "1px solid white",
                          margin: "5px 0",
                        }}
                      />
                    </>
                  )}
                  <p>{parseFormattedText(data?.content)}</p>
                </div>
                {data?.buttons && automaticReply(data?.buttons)}
              </>
            )}

            {/* time stamp */}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div className="timeStamp">
                <div>
                  <div>{formatDate(data.sent_time)} </div>
                  <div>
                    {data.sent_by
                      ? data.sent_by.name != " "
                        ? "Sent By: " + data.sent_by.name
                        : ""
                      : ""}
                  </div>
                </div>
                <div>
                  {bubble === "sender" &&
                    !data.is_private_note &&
                    status(data.recent_status.status)}
                </div>
              </div>
            </div>
          </div>
          <div>
            {showForwardIcon &&
              !data.is_private_note &&
              bubble === "received" && (
                <FontAwesomeIcon
                  icon={faShare}
                  style={{
                    cursor: "pointer",
                    color: "#d51f7b",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                  onClick={handleForwardClick}
                />
              )}
          </div>
        </div>
        {bubble === "sender" ? (
          <div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/f9/Phoenicopterus_ruber_in_S%C3%A3o_Paulo_Zoo.jpg"
              className="profilePic"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {showForwardModal && (
        <ForwardMessage
          onClose={() => setShowForwardModal(false)}
          message={data}
        />
      )}
    </>
  );
}

export default Message;
