import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useAuth } from "../../../../Context/Auth";
import DisplayContacts from "./DisplayContacts";

const ReplyMsgCard = ({
  data,
  onclick,
  getFileIcon,
  getFileName,
  parseFormattedText,
  scrollToMessage,
}) => {
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    if (data?.header?.header_content_url) {
      var link = data?.header?.header_content_url.split("?")[0];
      var file = link.split("/").pop();
      setFileName(file);
    }
  }, [data]);
  return (
    <div>
      <Box
        sx={{
          background: "rgba(4, 170, 109, 0.1)",
          height: "auto",
          padding: "5px",
          borderRadius: "0 0 5px 5px",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          borderTop: "3px solid #dedadb",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={() => scrollToMessage(`${data.message_id}`)}
      >
        <p
          style={{
            fontSize: "10px",
            marginBottom: "10px",
          }}
        >
          {data?.sent_by?.name}
        </p>
        {data?.message_type == "template" ? (
          <>
            <div
              style={{
                width: "200px",
              }}
            >
              {data?.header?.header_type == "image" && (
                <p>
                  <img
                    src={data?.header?.header_content_url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                      border: "1px",
                    }}
                  />
                </p>
              )}
              {data?.header?.header_type == "document" && (
                <>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    <PictureAsPdfIcon
                      style={{ marginRight: "5px", color: "white" }}
                    />
                    <span style={{ color: "white" }}>
                      {getFileName(fileName)}
                    </span>
                  </p>
                  <div
                    style={{
                      width: "100%",
                      borderTop: "1px solid white",
                      margin: "5px 0",
                    }}
                  />
                </>
              )}
              <p
                style={{
                  fontSize: "12px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {parseFormattedText(data?.content)}
              </p>
            </div>
          </>
        ) : data?.message_type == "attach_contact" ? (
          <>
            <div
              style={{
                width: data?.content.length > 300 ? "350px" : "225px",
                fontSize: "12px",
              }}
            >
              <DisplayContacts data={data} noViewMore={true} />
            </div>
          </>
        ) : data.message_type == "attach_file" ? (
          <>
            <div className={`messageAttachFile`}>
              {["image/jpeg", "image/png", "image/webp"].includes(
                data.file_type
              ) && data.file_link ? (
                <img
                  src={data.file_link}
                  style={{
                    width: "150px",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: "1px solid #da1e7a",
                    marginRight: "5px",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                    paddingTop: "5px",
                    borderTop: "1px solid #f2f2f2",
                  }}
                >
                  {getFileIcon(data.file_type)}
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {getFileName(data.file_name)}
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "200px",
              }}
            >
              {data?.header?.header_type == "image" && (
                <img
                  src={data?.header?.header_content_url}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                    border: "1px",
                  }}
                />
              )}
              {data?.header?.header_type == "document" && (
                <>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                    name="header"
                  >
                    <PictureAsPdfIcon
                      style={{ marginRight: "5px", color: "white" }}
                    />
                    <span style={{ color: "white" }}>{fileName}</span>
                  </p>
                  <Divider color="white" />
                </>
              )}
              <p style={{ fontSize: "12px" }}>
                {parseFormattedText(data?.content)}
              </p>
            </div>
          </>
        )}
      </Box>
    </div>
  );
};

export default ReplyMsgCard;
